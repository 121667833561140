/* Styles for the title of the product */
.untitled-product-title {
  font-weight: 800;
  font-size: 20px !important;
}

/* Styles for the preview text */
.preview-text {
  font-size: 17px !important;
}

/* Styles for the first button in the button group */
.bottom-header-main .button_group button:first-child {
  background: transparent;
  border: 2px solid #cbd6e1;
}

/* Styles for the second button in the button group */
.bottom-header-main .button_group button:last-child {
  background: #4f46e5;
  color: white;
}

.aside-menu--expanded {
  width: 75px !important;
}

.aside-menu--expanded-body-content {
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.side_bar_contract_form {
  z-index: 450;
  max-width: 350px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  right: -19px;
  border-right: 0px !important;
  border-top-right-radius: 0px !important;
  border-radius: 10px;
  /* margin: 10px; */
  top: 159px;
  height: 100%;
  border: 2px solid #cecece;
  transition: all 0.5s ease-in-out;
}

.side_bar_contract_form .sidebar_header {
  border-bottom: 1px solid #c1c1c1;
  text-align: center;
  padding: 20px 0px;
}
.side_bar_contract_form .sidebar_header h5 {
  margin-top: 40px;
}
.side_bar_contract_form .sidebar_header button {
  position: absolute;
  top: -5px;
  left: -15px;
}
.side_bar_contract_form .sidebar_header button i {
  font-size: 34px;
}

.hamburger_side {
  border: 0;
  background-color: transparent;
}
.label_tooltip {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  position: relative;
}

.form-check-label {
  color: black;
  font-weight: 500;
}

.toolTipContent {
  position: absolute;
  bottom: 96px;
  width: 250px;
  background-color: rgb(36, 36, 36);
  color: white;
  right: -2px;
  padding: 10px;
  z-index: 20;
  opacity: 0;
  transition: all 0.3s;
}
.label_tooltip:hover + .toolTipContent {
  opacity: 1;
}
.toolTipContent .arrowForm {
  position: absolute;
  top: 0px;
  transform: translate3d(0px, 105.6px, 0px);
}
.toolTipContent::after {
  content: " ";
  position: absolute;
  bottom: -6px;
  left: calc(50%);
  z-index: 1;
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
  transform-origin: center center;
  background: rgb(36 36 36);
}
.toolTipContent span {
  font-size: 12px;
  font-weight: 400;
}

.label_color {
  color: #186aa5;
}

.link_input input {
  border: none;
  outline: none;
  background: #e6e6e6;
  padding: 5px 10px;
  width: 90%;
  border-radius: 5px;
}
.form-check-input:checked {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.link_input input::placeholder {
  color: #000000;
}

.label_tooltip span {
  width: 20px;
  height: 20px;
  border: 2px solid #cecece;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.label_tooltip span i {
  font-size: 14px;
  color: #918686;
}

.form_sidebar .sidebar_header {
  text-align: center;
  border-bottom: 1px solid #cecece;
  padding: 30px 0px;
}

.form_sidebar .sidebar-content {
  padding: 30px 10px;
  height: 45vh;
}

.form_sidebar .sidebar-content h5 {
  font-size: 18px;
  font-weight: 400;
  color: #646464;
}

/* .form_sidebar .sidebar_main {
  height: calc(100vh - 150px);
  overflow: hidden;
} */

.form_sidebar .sidebar-bottom {
  min-height: 100px;
}

.sidebar-bottom {
  padding: 5px 10px;
}
.sidebar-bottom h5 {
  font-size: 18px;
}

.form_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  padding: 40px;
}
.form_container .form_input {
  position: relative;
  border: 0;
  padding: 15px 20px;
  border: 0;
  border: 2px dotted transparent;
  transition: all 0.3s;
}
.form_container .form_input:hover {
  border: 2px dashed #cbd6e1;
}
.form_container .form_input:hover .side_ellipse {
  opacity: 1;
}
.form_container .form_input:hover .plus_btn {
  opacity: 1;
}
.form_container .plus_btn {
  width: 32px;
  height: 32px;
  background-color: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  position: absolute;
  bottom: -17px;
  left: 50%;
  transition: all 0.3s;
  cursor: pointer;
  opacity: 0;
}

.form_container .plus_btn:hover {
  background-color: #4f46e5;
  color: white !important;
}
.form_container .plus_btn:hover i {
  color: white !important;
}
.form_container .plus_btn i {
  font-size: 15px;
  margin: 0 !important;
  color: #3c3c3c !important;
}

.side_ellipse {
  cursor: pointer;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  background: #e8eff7;
  padding: 5px;
  opacity: 0;
}

.dynamic-content-container {
  max-width: 600px;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  height: 477px;
  border: 1px dashed #707070;
  margin: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  top: -126px;
  visibility: hidden;
  transition: all 0.3s;
}

.dynamic-content-container .box-skeleton {
  width: 9px;
  height: 4px;
  background: #4f46e5;
  background: linear-gradient(to right, #716cce 5%, #4f46e5 18%, #4f46e5 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}
.dynamic-content-container .box_items {
  background: #e8eff7;
  width: 75px;
  row-gap: 4px;
  display: flex;
  padding: 7px 4px;
  margin: auto;
  flex-wrap: wrap;
  border-radius: 2px;
}

.dynamic-content-container .tab-content {
  background: white;
  height: 100%;
}
.dynamic-content-container .removeBoxBtn {
  position: absolute;
  right: 20px;
  border: 0;
  outline: none;
  bottom: -6px;
  background: transparent;
  font-size: 30px;
}

.dynamic-content-container h4 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-align: center;
}
.dynamic-content-container .content-header {
  background-color: #e8eff7;
}
.dynamic-content-container .nav-pills .nav-link {
  font-size: 18px;
  font-weight: 400;
}
.dynamic-content-container .nav .nav-pills {
  column-gap: 50px;
}
.dynamic-content-container .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000000;
  font-weight: bold;
  border-radius: 0;
  border-bottom: 2px solid #4f46e5;
}
.dynamic-content-container .content-box {
  margin: auto;
  width: 100px;
  height: 70px;
}
.dynamic-content-container .content-box .item_box {
  width: 100%;
  height: 50px;
  padding: 1px 0px;
  border-radius: 5px;
  align-items: center;
}
.dynamic-content-container .content-box h4 {
  font-size: 12px !important;
}

/* * Skeleton styles for loading state */
.dynamic-content-container .skeleton-one {
  width: 100%;
  height: 4px;
  /* background: #4f46e5 0% 0% no-repeat padding-box; */
  background: #4f46e5;
  background: linear-gradient(to right, #716cce 5%, #4f46e5 18%, #4f46e5 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}
.dynamic-content-container .skeleton-two {
  width: 80%;
  height: 2px;
  background: #4f46e5;
  background: linear-gradient(to right, #716cce 5%, #4f46e5 18%, #4f46e5 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}
.dynamic-content-container .skeleton-three {
  width: 80%;
  height: 2px;
  background: #4f46e5;
  background: linear-gradient(to right, #716cce 5%, #4f46e5 18%, #4f46e5 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}
.dynamic-content-container .skeleton-four {
  width: 95%;
  height: 2px;
  background: #4f46e5;
  background: linear-gradient(to right, #716cce 5%, #4f46e5 18%, #4f46e5 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

/* for column text */
.dynamic-content-container .column-text .skeleton-one {
  width: 100%;
  height: 6px;
  background: #4f46e5;
  background: linear-gradient(to right, #716cce 5%, #4f46e5 18%, #4f46e5 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}
.dynamic-content-container .column-text .skeleton-two {
  width: 60%;
  height: 2px;
  background: #4f46e5;
  background: linear-gradient(to right, #716cce 5%, #4f46e5 18%, #4f46e5 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}
.dynamic-content-container .column-text .skeleton-three {
  width: 90%;
  height: 1px;
  background: #4f46e5;
  background: linear-gradient(to right, #716cce 5%, #4f46e5 18%, #4f46e5 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}
.dynamic-content-container .column-text .skeleton-four {
  width: 90%;
  height: 1px;
  background: #4f46e5;
  background: linear-gradient(to right, #716cce 5%, #4f46e5 18%, #4f46e5 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.dynamic-content-container .column-image img {
  width: 26.5px;
  height: 26.5px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-squares 2s infinite ease-out;
}
.dynamic-content-container .cover-image img {
  width: 58px;
  height: 39px;
}
.dynamic-content-container .cover-image img {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-squares 2s infinite ease-out;
}
.twoFields .skeleton-box {
  background-color: #e8eff7;
  width: 30px;
  padding: 8px 4px;
  display: flex;
  align-items: center;
  margin: auto;
}
.shortText .skeleton-box {
  background-color: #e8eff7;
  width: 57px;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  margin: auto;
}
.longText .skeleton-box {
  background-color: #e8eff7;
  width: 57px;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  margin: auto;
  row-gap: 5px;
  flex-direction: column;
}
.shortText .skeleton-one {
  width: 100%;
  height: 1px;
  background: #4f46e5;
  background: linear-gradient(to right, #716cce 5%, #4f46e5 18%, #4f46e5 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}
.longText .skeleton-one {
  width: 100%;
  height: 1px;
  background: #4f46e5;
  background: linear-gradient(to right, #716cce 5%, #4f46e5 18%, #4f46e5 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}
.twoFields .skeleton-one {
  width: 100%;
  height: 1px;
  background: #4f46e5;
  background: linear-gradient(to right, #716cce 5%, #4f46e5 18%, #4f46e5 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.dropdown-image img {
  width: 62px;
}

.datePicker .skeleton-one {
  height: 2px;
}

.modal_contract_form .modal-dialog {
  max-width: 650px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.modal_contract_form .modal-dialog .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 20px;
  outline: 0;
  overflow: hidden;
}
.modal_contract_form .modal-dialog .modal-header {
  background: #4f46e5;
}
.modal_contract_form .modal-header-left-area p {
  color: white !important;
  font-size: 18px;
  margin: 0;
}
.modal_contract_form .modal-header-left-area .checkIcon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  margin-right: 8px;
  color: #4f46e5;

  border-radius: 4px;
}
.modal_contract_form .modal-header-left-area .checkIcon i {
  font-size: 14px !important;
}
.modal-body-warper span {
  font-size: 16px;
  font-weight: 400;
  display: block;
  max-width: 500px;
  width: 100%;
  margin: auto;
}

.modal-body-warper label {
  max-width: 65%;
  width: 100%;
}

.modal-body-warper .input-addons input {
  border: none;
  outline: none;
  background-color: #eeeff1;
  max-width: 65%;
  height: 36px;
  width: 100%;
  padding: 0px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.95;
}

@media (max-width: 426px) {
  .modal_contract_form .modal-dialog .modal-content {
    border-radius: 0px;
    overflow: hidden;
    height: 100%;
  }
  .modal_contract_form .modal-dialog .modal-header {
    background: #4f46e5;
    overflow: hidden;
    border-radius: 0;
  }
  .modal-body-warper label {
    max-width: 100%;
  }
  .modal-body-warper .input-addons input {
    max-width: 60%;
  }
  .modal_contract_form .modal-header-left-area p {
    color: white !important;
    font-size: 13px;
    margin: 0;
  }
}

.modal-body-warper .input-addons button {
  border: none;
  height: 36px;
  background-color: #131416;
  box-shadow: 0 2px 5px 0 rgba(19, 20, 22, 0.2);
  font-weight: 700;
  text-transform: uppercase;
  padding: 1px 20px 0;
  color: rgb(237, 235, 235);
  font-size: 15px;
}
@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes wave-squares {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@media (max-width: 500px) {
  .side_bar_contract_form {
    right: 0;
    border: 0;
  }
  .form_container {
    padding: 15px;
  }
  .dynamic-content-container {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .dynamic-content-container .removeBoxBtn {
    right: 17px;
    bottom: 5px;
  }

  .aside-menu--expanded {
    width: 50px !important;
    top: 166px !important;
  }
  .side_bar_contract_form .sidebar_header .hamburger_side_mobile {
    position: absolute;
    top: -5px;
    left: -23px;
  }
  .side_bar_contract_form .sidebar_header .hamburger_side_icon {
    font-size: 26px !important;
  }
  .side_bar_contract_form .sidebar_header {
    border-bottom: 1px solid #c1c1c1;
    text-align: center;
    padding: 0;
  }
}
