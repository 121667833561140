.ck-editor__editable {
  min-height: 500px;
}

/* Styles for the top section of the text editor */
.editor_top_section {
  background-color: #e8eff7;
  overflow-y: auto;
  border: 1.5px solid #c1c1c1;
  border-bottom: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Adjustments for button group z-index */
.btn-group {
  z-index: -1;
}

/* Styles for the smart text input */
.smart-text-input {
  top: 35%;
  left: 60%;
  z-index: 9;
}
.smart-text-input input {
  max-width: 133px;
  width: 100%;
  background-color: #e8eff7;
  padding: 10px 25px;
  border: 0;
  border-bottom: 1px dashed #4f46e5;
  outline: none;
}

.smart_text {
  min-width: 133px;
  width: 100%;
  background-color: #e8eff7;
  padding: 10px 25px;
  border: 0;
  border-bottom: 1px dashed #4f46e5;
  outline: none;
}

/* Styles for the content within the top section */
.editor_top_section_content {
  background: white;
  min-height: 40vh;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

/* Styles for the logo container */
.editor_top_section .Logo {
  width: 137px;
  height: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 4px solid #c1c1c1;
}

/* Styles for the header and image sections */
.editor_top_section .header,
.image {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styles for the SunEditor toolbar buttons */
.sun-editor .se-btn-tray,
.sun-editor .se-btn-module {
  padding: 5px 0px 0px 0px;
}
.sun-editor .se-menu-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
  margin-left: 15px;
}

/* Styles for the right-side box */
.right-side-box .icon-box {
  width: 120px;
  height: 89px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 25px;
}

/* Background styles for the right-side box */
.right-side-box .icon-box {
  background: #e8eff7 0% 0%;
}

/* Skeleton styles for loading state */
.right-side-box .icon-box .skeleton-one {
  width: 100%;
  height: 23px;
  background: #e8eff7 0% 0% no-repeat padding-box;
}
.right-side-box .icon-box .skeleton-two {
  width: 100%;
  height: 6px;
  background: #e8eff7 0% 0% no-repeat padding-box;
}
.right-side-box .icon-box .skeleton-three {
  width: 80%;
  height: 6px;
  background: #e8eff7 0% 0% no-repeat padding-box;
}
.right-side-box .icon-box .skeleton-four {
  width: 95%;
  height: 6px;
  background: #e8eff7 0% 0% no-repeat padding-box;
}

/* Active and hover styles for icon box */
.right-side-box .icon-box.active {
  border: 2px solid #4f46e5 !important;
}
.right-side-box .icon-box:hover {
  border: 2px solid #4f46e5 !important;
}

/* Styles for the right-side box title */
.right-side-box h5 {
  font-size: 18px;
  font-weight: normal;
}

/* Styles for the dropdown menu */
.right-side-box .dropdown-menu {
  width: 230px !important;
  background: #e8eff7 0% 0%;
  right: 24px !important;
}

/* Styles for icons in the dropdown menu */
.right-side-box .dropdown-menu .fa-pencil,
.right-side-box .dropdown-menu .fa-clone {
  font-size: 14px !important;
  color: #000 !important;
  margin: 0;
}
.right-side-box .dropdown-menu .fa-trash {
  font-size: 14px !important;
  color: #ff0505 !important;
  margin: 0;
}

/* Styles for link buttons in the right-side box */
.right-side-box .link-btn {
  display: flex;
  align-items: center;
  row-gap: 5px;
  color: black;
  font-weight: normal;
}
.right-side-box .link-btn svg {
  width: 16px;
  height: 16px;
}
.right-side-box .link-btn span {
  font-size: 20px;
}
.right-side-box .fa-plus {
  color: #727272 !important;
  font-size: 35px;
  margin: 0 !important;
}

/* Styles for ellipsis vertical icon */
.right-side-box .fa-ellipsis-vertical {
  color: #727272 !important;
  font-size: 45px;
}

/* Styles for the box title */
.right-side-box .box-title {
  font-size: 20px;
}

.right-side-box .pointer-event-none {
  pointer-events: none !important;
}

/* Styles for the text editor area */
.text-editor {
  height: 80vh;
  background-color: #e8eff7;
  overflow-y: auto;
  border: 1.5px solid #707070;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  overflow-x: hidden;
}

/* Styles for the SunEditor wrapper */
.sun-editor .se-wrapper .se-wrapper-inner {
  width: 100%;
  height: 100%;
  min-height: 70vh;
  overflow-y: auto;
  overflow-x: auto;
}

/* Custom styles for the custom action button */
.customAction {
  background: #e8eff7 !important;
  border-radius: 3px;
  color: #000 !important;
  height: 35px;
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  align-items: center;
}

/* Styles for the scrollbar in the text editor */
.text-editor::-webkit-scrollbar {
  width: 15px;
}
.text-editor::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.text-editor::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 15px;
  height: 50px;
}
.text-editor::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Styles for the SunEditor component */
.sun-editor {
  border: 1px solid #c1c1c1;
}
.sun-editor .se-toolbar {
  outline: 1px solid #c1c1c1;
}
.sun-editor button {
  color: #707070;
}

/* Styles for the CreateContract component */
.create-contract {
  margin: 0 !important;
}
.create-contract .offcanvas-end {
  top: 142px;
  border-top-left-radius: 18px;
  border: 2px solid #6f6f6f !important;
  border-right: 0px !important;
  max-width: 400px;
  width: 100%;
  border-bottom: 0 !important;
}
.create-contract .btn-close {
  width: 20px;
  height: 20px;
}
.create-contract .header {
  border-bottom: 2px solid #c1c1c1;
}

/* Styles for form labels */
.form-area label {
  font-size: 18px;
}
.form-area label svg {
  color: #8d8d8d;
}

/* Styles for note section */

.top-note p {
  margin-top: 56px;
  margin-bottom: 14px;
}
.note p {
  color: #000;
  font-size: 15px;
}

/* Styles for form controls */
.form-area .css-13cymwt-control {
  border: 1px solid #c1c1c1;
  box-shadow: none !important;
}
.form-area .css-t3ipsp-control:hover {
  border-color: #c1c1c1 !important;
}
.form-area .css-t3ipsp-control:focus {
  border-color: #c1c1c1 !important;
}

/* Additional styles for form controls */
.form-area .css-t3ipsp-control {
  box-shadow: none !important;
  box-sizing: border-box;
}
.form-area .css-t3ipsp-control {
  box-shadow: 0 0 0 1px #ffffff;
  box-sizing: border-box;
}
.form-area .form-control:focus {
  color: #757575;
  background-color: #fff;
  border-color: #c1c1c1;
  outline: 0;
  box-shadow: none !important;
}

/* Styles for button group */
.form-area .btn-group {
  border: 1.5px solid #e8eff7 !important;
  border-radius: 5px;
}

/* Styles for tab buttons */
.form-area .tab-btn {
  border: 1.5px solid #e8eff7 !important;
}
.form-area .form-control {
  color: #000;
}

/* Styles for active and focused tab buttons */
.btn-check:checked + .tab-btn,
.btn-check:active + .tab-btn,
.tab-btn:active,
.tab-btn.active,
.tab-btn.dropdown-toggle.show {
  color: #000;
  background-color: #e8eff7;
  border: 1.5px solid #4f46e5 !important;
  box-shadow: none !important;
}
.btn-check:checked + .tab-btn:focus,
.btn-check:active + .tab-btn:focus,
.tab-btn:active:focus,
.tab-btn.active:focus,
.tab-btn.dropdown-toggle.show:focus {
  box-shadow: none !important;
}

/* Styles for the bottom header section */
.bottom-header-main {
  background-color: #e8eff7;
}
.bottom-header .icon svg {
  font-size: 45px;
}
.bottom-header h5 {
  font-size: 18px;
}
.bottom-header p {
  color: #727272;
  font-size: 14px;
  margin: 0;
  column-gap: 2px;
}
.bottom-header-main .button_group {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

/* Styles for buttons in the bottom header */
.bottom-header-main .button_group button {
  padding: 10px 35px;
  border: 2px solid #4f46e5;
  border-radius: 30px;
  color: black;
  transition: all 0.3s;
}

.single_box h6,
span {
  font-size: 20px;
  font-weight: 500;
}

.single_box {
  padding: 5px 1.5rem !important;
  transition: all 0.3s;
  cursor: pointer;
}
.proposal-header {
  transition: all 0.3s;
}
.single_box:hover {
  background-color: rgb(232, 232, 232);
  font-size: 16px !important;
}

.add-page h2 {
  margin-top: 10px;
  font-size: 35px;
  font-weight: bold;
}
.add-page p {
  font-size: 20px;
  margin-top: 10px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: transparent;
}

@media (max-width: 500px) {
  .nav_mobile {
    align-items: start;
  }
  .bottom-header-main .button_group button {
    padding: 10px 20px;
  }
  .editor_top_section .Logo {
    width: 100%;
    height: 137px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 4px solid #c1c1c1;
  }
  .editor_top_section .header,
  .image {
    display: none;
  }
  .top_section {
    text-align: start !important;
    /* margin-top: 0 !important; */
  }
  .top_section h5 {
    font-size: 17px;
  }
  .top_section span {
    font-size: 15px;
  }
  .create-contract .offcanvas-end {
    top: 0;
    border-top-left-radius: 0;
    border: 0;
    max-width: 100%;
    width: 100%;
    border-bottom: 0 !important;
  }
}
