.contract-heading {
  font-weight: bold;
  font-size: 30px;
  color: #000;
  margin-bottom: 19px !important;
}
.contract-paragraph {
  font-size: 24px;
}
.contact-items {
  min-height: 19rem;
  margin-bottom: 2rem;
}
.fa-plus {
  color: #4f46e5 !important;
  font-size: 70px;
  font-weight: 500;
  margin-bottom: 40px !important;
}
.create-contract-text {
  font-size: 24px !important;
  color: #4f46e5 !important;
  font-weight: 600;
}
.basic-photo {
  font-size: 24px !important;
  color: #000 !important;
  font-weight: 700;
}
.fa-file-signature {
  font-size: 70px;
  margin-bottom: 40px !important;
}
.three-dot-btn .fa-solid.fa-ellipsis {
  color: #94a3b8;
}
.edit-btn p {
  font-size: 18px;
  font-weight: 600;
}
