.add-proposal {
  max-width: 400px;
  margin: auto;
  cursor: pointer;
}
.add-proposal h2 {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 38px;
  margin-right: 61px;
}

/* .create_contract_proposal {
  width: 400px;
  border: 2px solid #707070;
  border-radius: 5px;
  position: absolute;
  right: 0;
} */
.proposal_right_side .offcanvas {
  /* height: 710px; */
  top: 142px;
}

.offcanvas-body::-webkit-scrollbar {
  width: 10px;
}

.offcanvas-body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.offcanvas-body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.offcanvas-body::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.offcanvas.show {
  visibility: visible !important;
  opacity: 1 !important;
}
.offcanvas-end {
  transition: 0.3s ease-in-out !important;
}
@media (max-width: 500px) {
  .proposal_right_side .offcanvas {
    top: 0px;
  }
}
