body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin-top: 50px;
}
.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}
.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}
.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
 
}
.custom-control-label {
  font-weight: 400;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}
.forgot-password a {
  color: #167bff;
}


/* settings page */
.circular-input {
  border-radius: 50%;
}

.nav-tabs .nav-link {
  color: #727272 !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000000 !important;
  border-bottom: 1px solid #4f46e5 !important;
}

a#tab1.active {
  color: #000000 !important;
}

.nav-tabs {
  border-bottom: none !important;
}

.company-setting-box {
  max-width: 1042px;
  width: 100%;
  box-shadow: 0px 0px 6px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 50px 30px !important;
}

.fs30 {
  font-size: 30px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.border-right {
  border-right: 1px solid #cbd6e1;
  opacity: 1;
}

.fs24 {
  font-size: 24px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-weight: bold;
  opacity: 1;
}

.fs16 {
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.MainLogo {
  left: 805px;
  width: 115px;
  text-align: center;
  height: 100px;
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #94a3b8;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.SecondaryLogo {
  top: 1226px;
  left: 961px;
  max-width: 239px;
  width: 100%;
  height: 100px;
  padding: 15px;
  margin-right: -20px;

  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #94a3b8;
  border-radius: 5px;
  opacity: 1;
}
.DefaultHeaderlogo {
  width: 100%;
  top: 1410px;
  left: 806px;
  padding: 50px;
  text-align: center;
  /* height: 165px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #94a3b8;
  border-radius: 5px;
  opacity: 1;
}

.margin-top-80 {
  margin-top: 80px;
}

.form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cbd6e1;
  border-radius: 10px;
  opacity: 1;
  min-height: 60px !important;
  /* width: 440px; */
}

.btn-primary {
  background-color: #4f46e5;
}

.text-content {
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 400;
  margin-top: 5px;
}
.SecondaryLogopath {
  padding: 10px;
  opacity: 1;
  width: 60px !important;
  height: 60px;
  object-fit: contain;
}
.DefaultHeaderlogoImg {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.HeaderPreview {
  height: 144px;
  text-align: -webkit-center;
  border-bottom: 1px solid #c1c1c1;
  position: relative;
  opacity: 1;
}
.CUSTOMERLOGO {
  top: 0px;
  left: 1472px;
  width: 44px;
  height: 22px;
  margin-top: 16px;
  text-align: center;
  font: normal normal bold 8px/11px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.headermainsection {
  overflow: hidden;
  top: 1220px;
  left: 1248px;
  /* width: 498px; */
  min-height: 476px;
  /* height: 100%; */
  padding: 20px 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #c1c1c1;
  opacity: 1;
}
.center-cirlce-header {
  width: 66px;
  position: relative;
  border-radius: 50%;
  height: 66px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 4px solid #c1c1c1;
  opacity: 1;
  top: 5px;
}

.section1 {
  top: 1377px;
  left: 1293px;
  width: 173px;
  height: 9px;
  /* UI Properties */
  background: #c1c1c1 0% 0% no-repeat padding-box;
  opacity: 1;
}
.section2 {
  top: 1399px;
  left: 1293px;
  width: 290px;
  height: 9px;
  /* UI Properties */
  background: #c1c1c1 0% 0% no-repeat padding-box;
  opacity: 1;
}
.section3 {
  top: 1421px;
  left: 1293px;
  width: 258px;
  height: 9px;
  /* UI Properties */
  background: #c1c1c1 0% 0% no-repeat padding-box;
  opacity: 1;
}
.section4 {
  top: 1443px;
  left: 1293px;
  width: 416px;
  height: 9px;
  /* UI Properties */
  background: #c1c1c1 0% 0% no-repeat padding-box;
  opacity: 1;
}

p.p-fs-10 {
  position: relative;
  top: 32px;
  font-size: 10px;
  font-weight: 800;
}

p.p-fs-15 {
  bottom: -16px;
  font-size: 9px;
  position: relative;
}

span.headerinner {
  position: relative;
  font-size: 12px;
  float: left;
  left: 34px;
  font-weight: 500;
}

span.innserimage {
  position: relative;
  font-size: 12px;
  float: right;
  left: -15px;
  font-weight: 500;
}

p.cricledot {
  border-radius: 50%;
  background: black;
  width: 18px;
  height: 18px;
  position: relative;
  top: 22px;
}

.m-2.border-custom-colour {
  border: 1px solid;
  width: 50%;
}

p.cricledot {
  border: 12px;
  border-radius: 50%;
  background: #191c24;
  width: 20px;
  position: relative;
  display: inline-block;
  height: 20px;
  top: 0px;
}
.m-2.border-custom-colour.d-flex {
  border: 1px solid;
  width: 50%;
  padding: 6px;
  height: 30px;
  column-gap: 10px;
  justify-content: center;
}

.text-primary {
  color: #4f46e5 !important;
}
.bg-primary {
  color: #4f46e5 !important;
}
span.fs-15 {
  font-weight: bold;
  font-size: 16px;
}

.card.paymentgateway {
  height: 197px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.paymentgatewaybtn {
  width: 335px;
  height: 53px;
  background: #4f46e5 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  padding: 5px;
  margin-left: -12px;
}

.paymentgateway.col-md-5.col-lg-5 {
  background: #fff;
  margin: 15px;
  max-height: 200px;
}
img.rounded.img-fluid {
  width: 204px;
  height: 140px;
  padding: 30px;
  object-fit: cover;
}

.payment-gate-way {
  background: white;
  padding: 0;
  height: 200px;
  overflow: hidden;
}
.payment-gate-way .image {
  height: 156px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-gate-way .paypal {
  width: 154px;
  height: 51px;
  object-fit: cover;
}
.payment-gate-way .square {
  width: 204px;
  height: 97px;
  object-fit: contain;
}
.payment-gate-way .stripe {
  width: 203px;
  height: 97px;
  object-fit: contain;
}
.payment-gate-way .payment-gate-way-footer {
  background-color: #4f46e5;
  color: white;
  border-radius: 0;
}
.payment-gate-way .payment-gate-way-footer .credit {
  font-size: 14px;
  font-weight: 500;
  display: flex !important;
  align-items: center;
}
.addAttendant_btn {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.addAttendant_btn span {
  font-size: 18px;
  font-weight: 700px;
}
.addAttendant_btn i {
  background-color: #4f46e5;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white !important;
  margin: 0 !important;
}
.form-switch .form-check-input {
  width: 44px;
  height: 24px;
  margin-right: 10px;
}
.form-check-input:checked {
  background-color: #4f46e5;
  border-color: #4f46e5;
}
.form-check-input:focus {
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.upload-btn-wrapper {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.upload-btn-wrapper span {
  font-size: 17px;
  font-weight: 500;
}
.upload-btn-wrapper img {
  width: 145px;
  height: 133px;
  margin-bottom: 10px;
}
.doubleEllipsis i {
  color: #b1b1b1;
}
.singleEllipsis i {
  color: #4f46e5;
}
@media (max-width: 400px) {
  .MainLogo {
    width: 100%;
  }
  .company-setting-box {
    padding: 50px 10px !important;
  }
}

.ant-modal,.css-dev-only-do-not-override-1r287do,.ant-modal-content{
  width: 800px !important;
  height: 500px !important;
}
