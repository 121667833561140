/* .tab_content_invoice {
  background-color: #e8eff7;
  box-shadow: 0px 0px 6px #0000004d;
  border-radius: 6px;
  min-height: 80vh;
  padding: 30px 0px;
  height: 100%;
  overflow-y: auto;
} */

.invoice-heading {
  font-weight: bold;
  font-size: 30px;
  color: #000;
  margin-bottom: 19px !important;
}
.invoice-paragraph {
  font-size: 24px;
}
.invoice-items {
  min-height: 19rem;
  margin-bottom: 2rem;
}
.fa-plus {
  color: #4f46e5 !important;
  font-size: 70px;
  font-weight: 500;
  margin-bottom: 40px !important;
}
.create-invoice-text {
  font-size: 24px !important;
  color: #4f46e5 !important;
  font-weight: 600;
}
.firefly-invoice {
  font-size: 24px !important;
  color: #000 !important;
  font-weight: 700;
}
.fa-file-signature {
  font-size: 70px;
  margin-bottom: 40px !important;
}
.three-dot-btn .fa-solid.fa-ellipsis {
  color: #94a3b8;
}
.edit-btn p {
  font-size: 18px;
  font-weight: 600;
}

/* create invoice css */

.untitled-product-title {
  font-weight: 800;
  font-size: 20px !important;
}

/* Styles for the preview text */
.preview-text {
  font-size: 17px !important;
}

/* Styles for the first button in the button group */
.bottom-header-main .button_group button:first-child {
  background: transparent;
  border: 2px solid #cbd6e1;
}

/* Styles for the second button in the button group */
.bottom-header-main .button_group button:last-child {
  background: #4f46e5;
  color: white;
}
