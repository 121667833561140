.invoice_container {
  background-color: #e8eff7;
  overflow-y: auto;
  border: 1.5px solid #707070;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  overflow-x: hidden;
}
.payment_schedule_container .payment_schedule {
  display: grid;
  grid-template-rows: minmax(24px, auto);
  padding: 20px 0px;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;

  border-bottom: 1.6px solid;
}
.payment_schedule_container .payment_schedule_content {
  display: grid;
  grid-template-rows: minmax(24px, auto);
  padding: 20px 0px;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  border-bottom: 1.6px solid;
}

.payment_schedule span {
  font-size: 14px;
  line-height: 1.29;
  color: black;
}
.payment_schedule_content span {
  font-size: 14px;
  line-height: 1.29;
  color: #2f2f2f;
}
.due_date_box {
  position: absolute;
  top: -78px;
  left: 140px;
  border-radius: 3px;
  font-size: 14px;
  max-width: 500px;
  width: 325px;
  cursor: pointer;
  font-family: ProximaNova, Arial, sans-serif;
  z-index: 999;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 18, 71, 0.15) 0px 14px 28px 0px,
    rgba(0, 18, 71, 0.08) 0px 0px 10px 0px;
  min-height: 50px;
  min-width: 50px;
  padding: 15px;
  pointer-events: auto;
}

.hover_item {
  background: #edeff1 !important;
  padding: 5px 14px;
  cursor: pointer;
}

.payment_schedule_container table {
  /* border: 1px solid #ccc; */
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  background: transparent;
}

.payment_schedule_container table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

.payment_schedule_container table tr {
  background-color: transparent;
  border-bottom: 1.7px solid #2f2f2f;
  padding: 0.35em;
}

.payment_schedule_container table th,
table td {
  padding: 18px 8px;
  text-align: center;
}

.payment_schedule_container table th {
  font-size: 0.85em;
  text-transform: uppercase;
}
.payment_schedule_container table td span {
  font-size: 0.85em;
}

@media screen and (max-width: 600px) {
  .payment_schedule_container table {
    border: 0;
  }

  .payment_schedule_container table caption {
    font-size: 1.3em;
  }

  .payment_schedule_container table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .payment_schedule_container table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .payment_schedule_container table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .payment_schedule_container table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .payment_schedule_container table td:last-child {
    border-bottom: 0;
  }
}

.nav-link.active .icon-box {
  border: 2px solid #4f46e5 !important;
}
.invoice_td:hover .tooltip_table {
  display: block;
  opacity: 1; /* Ensure opacity is set to 1 when display is set to block */
  transition: opacity 0.5s; /* Apply transition only to opacity property */
}
.purchase_td:hover .tooltip_table {
  display: block;
  opacity: 1; /* Ensure opacity is set to 1 when display is set to block */
  transition: opacity 0.5s; /* Apply transition only to opacity property */
}
.tooltip_table {
  position: absolute;
  top: -98px;
  left: -67px;
  width: 240px;
  min-height: 103px;
  background-color: #000;
  color: white;
  padding: 20px;
  border-radius: 5px;
  opacity: 0; /* Set initial opacity to 0 */
  transition: opacity 0.5s; /* Apply transition to opacity property */
  display: none;
}
.tooltip_table .tooltip_content {
  text-align: center;
}
.tooltip_content p {
  font-size: 14px !important;
  font-weight: 400;
  margin: 0;
  color: white !important;
}
.arrow_tool_tip {
  position: absolute;
  top: 0px;
  transform: translate3d(0px, 105.6px, 0px);
}
.arrow_tool_tip::after {
  content: " ";
  position: absolute;
  top: -9px;
  left: calc(50% - -72px);
  z-index: 1;
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
  transform-origin: center center;
  background: rgb(0, 0, 0);
}
.right-side-box .icon-box {
  border: 2px solid #c1c1c1;
}

.right-side-box .icon-box-add-page {
  width: 120px;
  height: 89px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 25px;
  background-color: #e8eff7;
}

.add-page h2 {
  margin-top: 10px;
  font-size: 35px;
  font-weight: bold;
}
.add-page p {
  font-size: 20px;
  margin-top: 10px;
}
.section_container {
  background: white;
  box-shadow: 0px 0px 6px #0000004d;
  border-radius: 6px;
}
.customer_details {
  padding: 40px;
}
.customer_details .logo {
  border: 2px solid #c1c1c1;
  padding: 10px;
  max-width: 200px;
  width: 100%;
  margin-bottom: 10px;
}
.customer_details .logo h4 {
  font-size: 18px;
  display: block;
}
.customer_details .title h4 {
  font-size: 18px;
  font-weight: 700;
}
.customer_details .item {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}
.customer_details .item span {
  font-size: 17px;
  font-weight: 400;
}

.customer_details p {
  font-size: 15px;
  font-weight: 400;
  margin-top: 36px;
}
.invoice .invoice-table {
  padding: 0px 40px;
}
.invoice .invoice-table .table_item {
  padding: 10px 0px;
}
.invoice .invoice-table .table_item span {
  font-size: 16px;
}

.invoice .invoice-table .hover_effect {
  border-bottom: 3px dashed #c1c1c1;
  transition: all 0.3s;
}
.invoice .invoice-table .hover_effect:hover {
  border-bottom: 3px dashed #5d00ff;
}

.invoice .service_area {
  overflow-x: auto;
  margin-top: 100px;
  /* margin-bottom: 50px; */
  padding: 0px 40px;
  border-bottom: 2px solid #0000004d;
}
.create_new_service {
  margin: 0px 40px;
  border-bottom: 2px solid #0000004d;
}
.invoice .create_new_service,
.service_area,
.service_item span {
  font-size: 16px;
}
.unitAccordionContent {
  max-width: 200px !important;
  width: 100%;
  border-radius: 5px;
}
.accordion-header {
  padding: 0px 20px !important;
  background: transparent;
  /* border: none; */
  transition: all 0.3s;
}
.accordion-header:hover {
  background: white;
}
.qyt input {
  max-width: 50px;
  width: 100%;
  height: 41px;
  border: none;
  outline: none;
  text-align: center;
  background: transparent;
  transition: all 0.3s;
  border-radius: 5px;
}
.qyt input:hover {
  background-color: rgb(221, 221, 221);
}

.service_info_top {
  width: 179px;
}
.service_info {
  max-width: 227px;
  width: 100%;
}
.service_info input {
  width: 100%;
  border: none;
  outline: none;
  background: #e8eff7;
  padding: 4px 6px;
  font-weight: 600;
  font-size: 15px;
}
.desc input {
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  padding: 4px 6px;
  font-size: 14px;
  transition: all 0.3s;
  border-radius: 4px;
}
.desc input:hover {
  background-color: #e1e5eb;
}

.create_new_service button {
  background: transparent;
  border: none;
  outline: none;
  color: #4f46e5;
  font-weight: 700;
}
.add-new-payment button {
  background: transparent;
  border: none;
  outline: none;
  color: #4f46e5;
  font-weight: 700;
}

.create_invoice_table > :not(caption) > * > * {
  padding: 10px 12px !important;
}
.table thead tr th td {
  font-size: 14px !important;
  color: #2b2b2b;
}
.table > :not(caption) > * > * {
  padding: 1.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.create_invoice_table span {
  font-size: 14px !important;
  color: #2b2b2b;
  font-weight: 500;
}
.select_item_box {
  position: absolute;
  border: 1px solid #5e5959;
  z-index: 9;
  width: 30vw;
  max-width: 450px;
  min-width: 200px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 8px 0px,
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 0px 0px 8px;
}
.select_item_box .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000000;
  background-color: transparent;
  border-bottom: 2px solid #4f46e5;
  border-radius: 0;
  font-weight: 500;
}
.select_item_box .nav {
  border-bottom: 1px solid;
}
.select_item_box .nav .nav-link {
  width: 90px;
  color: rgb(19, 19, 19);
  font-weight: 400;
}

.select_item_box .tab_box_item .single_item h5 {
  font-size: 16px;
  font-weight: 500;
}
.select_item_box .tab_box_item .single_item .cart_item i,
p {
  color: #b0adad;
}

.delete_btn {
  right: 12px;
  top: 6px;
  display: none;
}
.create_new_service {
  transition: all 0.3s;
}
.create_new_service:hover .delete_btn {
  display: block;
}
.delete_btn button {
  background: none;
  border: none;
  color: red;
}

.edit_discount_box {
  position: absolute !important;
  border-radius: 3px;
  font-size: 14px;
  width: 250px;
  right: 0;
  cursor: pointer;
  z-index: 1000;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 18, 71, 0.15) 0px 14px 28px 0px,
    rgba(0, 18, 71, 0.08) 0px 0px 10px 0px;
  min-height: 50px;
  min-width: 50px;
  padding: 15px;
  pointer-events: auto;
  border-radius: 5px;
  top: -28px;
  left: -243px;
}
.edit_tax_box {
  width: 280px !important;
}
.edit_tax_box input {
  width: 70% !important;
}
.edit_discount_box .box_header h5 {
  font-size: 16px;
  color: rgb(134, 138, 152);
}

.edit_discount_box .box-content input {
  width: 100%;
  border: none;
  outline: none;
  background: rgb(246, 247, 248);
  height: 36px;
  border-radius: 5px;
  padding: 0px 5px;
}
.discount_arrow {
  position: absolute;
  top: 0;
  transform: translate3d(0px, 52.8px, 0px);
  right: 8px;
}
.discount_arrow::after {
  content: " ";
  position: absolute;
  z-index: 1;
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
  transform-origin: center center;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 18, 71, 0.08) 4px -4px 6px;
  top: calc(50% - 10px);
}

.due_date_box .due_date_box_header h4 {
  font-size: 18px;
}

.due_date_box .form-select {
  padding: 15px !important;
}

.date_input input[type="date"] {
  background-color: rgb(246, 247, 248);
  padding: 15px;
  width: 100%;
  color: #000;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.arrow {
  position: absolute;
  top: 0px;
  transform: translate3d(0px, 105.6px, 0px);
}
.arrow::after {
  content: " ";
  position: absolute;
  top: calc(50% - 6px);
  left: -21px;
  z-index: 1;
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
  transform-origin: center center;
  background: rgb(255, 255, 255);
}

.details_top .logo {
  width: 100px;
  height: 100px;
  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.details_top {
  border-bottom: 2px solid rgb(180, 173, 173);
}
.details_top .logo h4 {
  font-size: 15px;
}
.details_top .details_text {
  text-align: start;
  margin-left: 20px;
}
.details_top .details_text h6 {
  font-size: 17px;
  font-weight: 500;
}
.details_top .details_text span {
  font-size: 15px;
  font-weight: 400;
}
.details_top .title h4 {
  font-size: 17px;
  cursor: pointer;
}
.payment_form_container .due_amount span {
  font-size: 15px;
  font-weight: 400;
}
.payment_form_container .due_amount h4 {
  font-size: 24px;
  font-weight: 700;
}

.payment_form_container .submit_button {
  border: none;
  border-radius: 30px;
  text-align: end;
  background-color: #4f46e5;
  max-width: 200px;
  width: 100%;
  color: white;
  height: 46px;
  text-align: center;
  transition: all 0.3s;
}
.payment_form_container .submit_button:hover {
  background-color: #5851df;
}

.way_to_pay_nav {
  background: transparent;
  max-width: 255px;
  width: 100%;
  border-radius: 4px;
  border: 1.5px solid #c1c1c1;
}
.way_to_pay .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black;
  background-color: #e6e5fc;
}

.way_to_pay .nav-pills .nav-link {
  border-radius: 0;
}

.due_date_box_content button {
  border: none;
  background-color: #4f46e5;
  color: white;
  border-radius: 5px;
  padding: 6px 15px;
}
.input_height {
  min-height: 36px !important;
}
.invoice_table {
  width: 700px;
}

/* Styling the scrollbar */
.table-responsive::-webkit-scrollbar {
  width: 5px;
}

.table-responsive::-webkit-scrollbar-track {
  background-color: transparent;
  height: 15px !important;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #e7e7e7;
  border-radius: 30px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #cdcbcb;
}

/* Payment schedule for mobile version */
.payment_schedule_mobile {
  background: white;
  box-shadow: 0px 0px 6px #0000004d;
  border-radius: 6px;
  padding: 32px;
  display: none;
}
.payment_schedule_mobile_content {
  border-bottom: 1px solid rgb(238, 239, 241);
}
.payment_schedule_mobile .mobile_title span {
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid rgb(238, 239, 241);
  padding: 0px 0px 20px 0px;
  display: block;
}

.payment_schedule_mobile .left_area span {
  font-size: 16px;
  color: rgb(19, 20, 22);
}
.payment_schedule_mobile .right_area p {
  font-size: 16px;
  font-weight: 600;
  color: rgb(113, 118, 132);
}
.payment_schedule_mobile .right_area .highlightColor {
  color: #ff6161 !important;
}

/* Media queries for mobile design */
@media (max-width: 1025px) {
  .invoice .invoice-table {
    padding: 0px 26px;
  }
}
.table_item_date_issued {
  padding-left: 70px !important;
}

@media (max-width: 500px) {
  .due_date_box {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 25px 50px -6px rgb(0 0 0 / 0.25);
  }
  .arrow {
    display: none;
  }
  .table_item_date_issued {
    padding-left: 0px !important;
  }
  .row_mobile {
    flex-direction: column-reverse;
  }
  .payment_schedule_main {
    display: none;
  }
  .payment_schedule_mobile {
    display: block;
  }
  .text-editor {
    height: 100%;
    background-color: transparent;
    overflow-y: auto;
    border: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    overflow-x: hidden;
  }
  .invoice .service_area {
    display: none !important;
  }
  .invoice_table {
    width: 500px;
  }
  .way_to_pay .nav {
    font-size: 14px;
  }
  .delete_btn {
    right: 12px;
    top: 58px;
    right: -25px;
    display: block;
  }
  .create_new_service {
    margin: 0px 26px;
    border-bottom: 2px solid #0000004d;
  }
}

::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
