/* Styles for buttons with primary class */
/* ============== All Events ============== */
button.but-primary {
  background: #4f46e5;
  max-width: 206px;
  width: 100%;
  height: 60px;
  border-radius: 28px;
  color: #fff;
  font-size: 21px;
  border: none;
}

.stage_container {
  column-gap: 25px;
  overflow-x: auto;
}

/* Styles for boxes */
.box {
  min-width: 95px;
  max-width: 150px;
  width: 100%;
  padding: 20px;
  background: white;
  box-shadow: 0px 0px 6px #00000029;
  color: black;
  margin-top: 50px;
  height: 130px;
  border-radius: 5px;
  position: relative;
}

.box.active {
  background-color: #111;
  color: white;
}

.box .arrow-left {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid rgb(241 244 249);
  top: 38%;
  left: 0;
}

.box .arrow-right {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid rgb(255, 255, 255);
  top: 38%;
  right: -9px;
}

.box .arrow-right.active {
  border-left: 10px solid rgb(0, 0, 0);
}

/* Hover styles for boxes */
.box:hover .arrow-left,
.box:hover .arrow-right {
  border-left: 10px solid rgb(0, 0, 0);
}

.box:hover {
  background-color: #111;
  color: white;
}

/* Styles for box content */
.box p {
  font-size: 30px;
  color: inherit;
  margin-bottom: 3px;
  color: #b0adad !important;
}

/* Styles for accordion content */
.accordion-content.active {
  display: block;
  max-height: 1500px;
  position: absolute;
  z-index: 9;
  padding: 5px 0px;
  width: 100%;
}

/* Styles for box headers */
.box h6 {
  font-size: 11px;
  font-weight: 500;
  color: inherit;
}

/* Styles for table elements */
.table-space tbody tr {
  margin-top: 20px;
  background-color: white;
  border: none;
}

.table thead tr th {
  padding: 10px !important;
  font-size: 13px;
  color: #999;
}

/* Styles for button containers */
.btn-container {
  position: absolute;
  display: none;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

tr:hover .btn-container {
  display: block;
  opacity: 1;
}

/* Styles for button containers within tables */
.btn-container button {
  margin-right: 5px;
}

/* Styles for specific button container positions */
.btn-container.first {
  left: 155px;
}

.btn-container.second {
  right: 0;
}

/* Styles for primary background color */
.bg-primary {
  background: #4f46e5 !important;
}

/* Styles for close button */
button.btn-close {
  color: #fff !important;
}

/* Styles for buttons within tables */
button.but-primary_tbl {
  background: #4f46e5;
  padding: 5px 15px;
  border-radius: 18px;
  color: #fff;
  font-size: 14px;
  border: none;
}

/* Hover styles for event links */
tr:hover .event_link a {
  color: #4f46e5;
}

/* Styles for event link text */
.event_link a {
  color: black;
}

/* ============== Customer modal ============== */

/* Styles for filter buttons */
.filter_bt {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0px 14px;
  text-transform: uppercase;
}

.filter_bt span {
  background: #fff;
  padding: 5px 13px;
  border-radius: 39px;
  border: 1px solid #ddd;
  color: #000;
  font-size: 14px;
  margin: 0px 20px;
  text-transform: uppercase;
}

/* Styles for modal table */
table.modal-tb tbody tr {
  background: #fff;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid hsl(0deg 0% 82% / 56%);
  border-radius: 13px;
  font-size: 13px;
}

table.modal-tb thead tr th {
  font-size: 15px;
  color: #000;
}

.modal-tb {
  margin-top: 40px;
}

table.modal-tb tbody tr td {
  padding: 19px;
  text-align: left;
}

.userInfoModal-content .form-check-input:checked {
  background-color: #000 !important;
  border-color: #000 !important;
}
.userInfoModal-content label {
  font-size: 16px;
  font-weight: 400;
  margin-top: 2px;
}
.tabs_btn .form-check-input:checked {
  background-color: #000 !important;
  border-color: #000 !important;
}
.tabs_btn label {
  font-size: 16px;
  font-weight: 400;
  margin-top: 2px;
}
/* ============== event modal ============== */

/* Styles for input with button */

/* Styles for event modal */
.event_modal {
  border-radius: 15px;
}

.event_modal_cont input {
  padding: 18px;
  margin-top: 7px;
}
.event_modal_cont {
  margin-top: 2.5rem !important;
}
.newEventsModal_dialog {
  max-width: 800px;
  width: 100%;
}
.newEventsModal_dialog .modal-content {
  overflow: hidden;
  border-radius: 20px;
  border: 0;
}

.create-event {
  padding: 18px;
  width: 200px;
  margin: 0px auto;
  border-radius: 64px;
}

/* Styles for borderless table */
.table-border-less > :not(caption) > * > * {
  border-bottom: 1px solid #ddd !important;
  padding: 18px;
}

/* Styles for active nav link */
.nav-tabs .nav-link.active {
  border-bottom: 3px solid blue !important;
}

/* Styles for nav tabs */
.nav-tabs .nav-link {
  border: none !important;
}

.nav-tabs {
  border-bottom: none;
}

/* Styles for form labels */
label.form-label {
  color: #000;
  font-size: 16px;
  margin-top: 10px;
  font-weight: normal;
}

/* Styles for selected options */
.selected-option {
  font-size: 15px !important;
}

/* Styles for background color */
.back_color {
  background-color: #f1f4f9;
}

/* Styles for icon size */
.icon_size {
  font-size: 35px;
}

/* Styles for amount */
.amou_nt {
  font-size: 18px !important;
  color: #000 !important;
  font-weight: 400;
}

/* Styles for spaced tbody */
.spaced-tbody {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Hover styles for nav links */
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: none;
}

/* Active styles for nav links */
.nav-tabs .nav-link:active,
.nav-tabs .nav-link:focus {
  border-bottom: 3px solid blue;
}

/* Hover styles for generic links */
.nav-link:hover,
.nav-link:focus {
  color: #000;
}

/* Styles for generic nav links */
.nav-link {
  color: #000;
}

/* Styles for table elements */
.table_events > :not(caption) > * > * {
  padding: 1.5rem 0.9rem !important;
}
.table_events td {
  font-size: 14px;
  text-align: left;
}

.customer_box_container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.customer_box_container .customer_box {
  padding: 10px;
  border-radius: 5px;
}
.customer_box_container .customer_box .customer_info p {
  color: black;
  font-size: 16px;
}
.customer_box_container .customer_box .customer_info span {
  font-size: 15px;
}
/* Styles for specific links */
.Mich_ael {
  text-decoration: none;
}

/* Styles for button group */
.btn-group {
  z-index: 1 !important;
}

/* Styles for dropdown toggle */
.dropdown-toggle::after {
  display: none !important;
}
.close {
  border: none;
  background: transparent;
  color: white;
}
.close i {
  font-size: 30px;
}
.event_modal {
  min-height: 600px;
  height: 100%;
  overflow: hidden;
  border: 0;
}

/* Media queries for smaller screens */

@media (max-width: 500px) {
  .newEventsModal_dialog {
    margin: auto;
  }
}

@media (max-width: 923px) {
  .filter_bt {
    flex-direction: column;
    justify-content: center !important;
    text-align: center;
    row-gap: 10px;
  }
}
