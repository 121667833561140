/* Styles for the title in the settings */
.setting_title h2 {
  font-size: 48px;
  font-weight: 800;
}

/* Styles for the navigation tabs in the settings */
.setting_tab_nav {
  display: flex;
  align-items: center;
}

.setting_tab_nav .active {
  color: #000000 !important;
  border-bottom: 2px solid #4a41d4 !important;
}

.setting_tab_nav a {
  color: #727272 !important;
  font-size: 22px !important;
}

/* Styles for the company tab navigation links */
.company-tab .nav-link {
  font-size: 18px !important;
  font-weight: normal;
  color: #c1c1c1 !important;
  transition: all 0.3s;
}

.company-tab .nav-link:hover {
  color: #000 !important;
}

.company-tab .nav-link.active {
  color: #000 !important;
}

/* Styles for the company brand section */
.company_brand .title {
  font-size: 25px;
  font-weight: 800;
}

.company_brand .company_brand_form {
  column-gap: 35px !important;
}

.company_brand .company_brand_form h5 {
  color: #000000;
  font-size: 22px;
  font-weight: 700;
}

.company_brand .company_brand_form p {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
}

.company_brand .company_brand_form .company_name {
  position: relative;
  border-right: 2px solid #bac4d1;
}

.company_brand .company_brand_form .company_name .icon {
  position: absolute;
  top: 0;
  right: 12px;
  color: #94a3b8;
}

@media (max-width: 425px) {
  .setting_tab_nav a {
    font-size: 16px !important;
  }
  .company_brand .company_brand_form .company_name {
    border-right: 0px;
  }
}
